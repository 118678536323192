import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PartnersCarousel from "../components/partners/PartnersCarousel";
import HeroVideo from "../components/hero-video";
import LineupSection from "../components/lineup-section";
import { LandingPageWrapper } from "../components/PageContainer";

const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Home" />
            <LandingPageWrapper>
                <HeroVideo />
                <LineupSection />
                <PartnersCarousel />
            </LandingPageWrapper>
        </Layout>
    );
};

export default IndexPage;
