import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";

import {
    Wrapper,
    SliderWrapper,
    StyledImg,
    Description,
    Date,
    Details,
} from "./styles";

const slideSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    fade: true,
    speed: 1500,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
};

const HeroVideo = () => {
    const image = useStaticQuery(graphql`
        fragment ImageFragment on File {
            childImageSharp {
                fluid(
                    maxWidth: 3000
                    maxHeight: 1500
                    grayscale: false
                    quality: 100
                ) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        fragment HeroFragment on File {
            childImageSharp {
                fluid(
                    maxWidth: 3000
                    maxHeight: 1200
                    grayscale: false
                    quality: 100
                ) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        query {
            thumbnail_1: file(relativePath: { eq: "KOFFIA_Website_2024.png" }) {
                ...HeroFragment
            }
            thumbnail_2: file(
                relativePath: { eq: "films/photos/BOYS_STI-PRE-01.jpg" }
            ) {
                ...HeroFragment
            }
            thumbnail_3: file(
                relativePath: { eq: "films/photos/CHEON_STI-PRE_01.jpg" }
            ) {
                ...HeroFragment
            }
            thumbnail_4: file(
                relativePath: { eq: "films/photos/COK_still_08.jpg" }
            ) {
                ...HeroFragment
            }
            thumbnail_5: file(
                relativePath: { eq: "films/photos/DD_STI-PRE_14.jpg" }
            ) {
                ...HeroFragment
            }
            thumbnail_6: file(
                relativePath: { eq: "films/photos/dont-buy-the-seller-3.jpeg" }
            ) {
                ...HeroFragment
            }
            thumbnail_7: file(relativePath: { eq: "films/photos/faq-1.jpg" }) {
                ...HeroFragment
            }
            thumbnail_8: file(
                relativePath: { eq: "films/photos/LOVE-RESET-03.jpg" }
            ) {
                ...HeroFragment
            }
            thumbnail_9: file(
                relativePath: { eq: "films/photos/Picnic-2.jpg" }
            ) {
                ...HeroFragment
            }
        }
    `);

    return (
        <Wrapper>
            <SliderWrapper>
                <Slider {...slideSettings}>
                    <StyledImg
                        fluid={image.thumbnail_1.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_2.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_3.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_4.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_5.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_6.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_7.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_8.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_9.childImageSharp.fluid}
                    />
                </Slider>
            </SliderWrapper>
            <Description>
                2024 Korean Film Festival in Australia
                <Details>
                    Experience the extraordinary storytelling, breathtaking
                    cinematography, and powerful performances as the Korean Film
                    Festival in Australia (KOFFIA) returns for its 15th year
                    down under!
                </Details>
                <Details>
                    KOFFIA 2024 features a stellar line-up of Australian
                    Premieres from Korea's top filmmakers, spanning genres like
                    Thriller, Crime, Rom-com, Drama, Fantasy, Mystery, Family,
                    Comedy, and Action. Each film offers a unique cinematic
                    experience that showcases the best of Korean cinema.
                </Details>
                <Details>
                    Presented by the Korean Cultural Centre Australia, KOFFIA
                    2024 will highlight the unique culture of Korea, providing a
                    cinematic journey for all tastes, ages, and cultural
                    backgrounds. Every film is screened with English subtitles,
                    ensuring everyone can enjoy the magic of Korean cinema.
                </Details>
                <Details>
                    Don't miss this unforgettable celebration of Korean film!
                </Details>
                <Details>
                    ** The booking links will be activated at least 3 weeks
                    prior to the screenings. **
                </Details>
            </Description>
        </Wrapper>
    );
};

export default HeroVideo;
