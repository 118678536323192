import React from "react";
import Image from "./Images";
import Slider from "react-slick";
import {
    StyledAnchor,
    CarouselImg,
    CarouselSmallImg,
    CarouselLargeImg,
    CarouselContainer,
    CarouselWrapper,
} from "./styles";

const PartnersCarousel = () => {
    const image = Image();
    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 7000,
        pauseOnHover: true,
        initialslide: 0,
        variableWidth: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                },
            },
            {
                breakpoint: 690,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                },
            },
        ],
    };

    return (
        <CarouselWrapper>
            <CarouselContainer>
                <Slider {...settings}>
                    <StyledAnchor
                        href="https://english.visitkorea.or.kr/enu/KOO/KOO_EN_10_3.jsp"
                        target="_blank"
                    >
                        <CarouselImg fixed={image.ktoCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://www.ofoodglobal.com/"
                        target="_blank"
                    >
                        <CarouselImg fixed={image.oFoodCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://www.jonggaglobal.com/"
                        target="_blank"
                    >
                        <CarouselImg fixed={image.jonggaCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://flyasiana.com/C/AU/EN/index"
                        target="_blank"
                    >
                        <CarouselImg fixed={image.asianaCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://www.nongshim.com.au/"
                        target="_blank"
                    >
                        <CarouselImg fixed={image.nongshimCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="http://overseas.mofa.go.kr/au-sydney-en/index.do"
                        target="_blank"
                    >
                        <CarouselSmallImg fixed={image.cgroksCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://alicesprings.nt.gov.au/"
                        target="_blank"
                    >
                        <CarouselSmallImg
                            fixed={image.alicespringsCarouselImage}
                        />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://www.benalla.vic.gov.au/Home"
                        target="_blank"
                    >
                        <CarouselSmallImg fixed={image.benellaCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://www.burnside.sa.gov.au/Home"
                        target="_blank"
                    >
                        <CarouselSmallImg fixed={image.burnsideCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://originalspin.com.au/"
                        target="_blank"
                    >
                        <CarouselSmallImg
                            fixed={image.originalSpinCarouselImage}
                        />
                    </StyledAnchor>
                    <StyledAnchor
                        href="http://gon-media.com/1_1.php"
                        target="_blank"
                    >
                        <CarouselSmallImg fixed={image.gonCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://www.eventcinemas.com.au/"
                        target="_blank"
                    >
                        <CarouselImg fixed={image.eventCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://www.palacecinemas.com.au/"
                        target="_blank"
                    >
                        <CarouselImg fixed={image.palaceCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://www.theregaltheatre.com.au/"
                        target="_blank"
                    >
                        <CarouselImg fixed={image.regalCarouselImage} />
                    </StyledAnchor>
                    <StyledAnchor
                        href="https://www.unsw.edu.au/"
                        target="_blank"
                    >
                        <CarouselImg fixed={image.unswCarouselImage} />
                    </StyledAnchor>
                </Slider>
            </CarouselContainer>
        </CarouselWrapper>
    );
};
export default PartnersCarousel;
